import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ReactSession }  from 'react-client-session';
import { useNavigate } from 'react-router-dom';
import numeral from 'numeral';
import SideBar from './sidebar';
import EsideBar from './esidebar';
import NavBar from './navbar';
import Footer from './footer';
import Popup from 'reactjs-popup';
import './test.css';
import logo from '../images/logo_full.png';
function App ()  {

  const userId = ReactSession.get("userId");
  const userType = ReactSession.get("userType");
 
  
   const [currentDateTime, setCurrentDateTime] = useState(new Date());
   //console.log(currentDateTime);
  const [grandTotal, setGrandTotal] = useState(0);
    const [subTotal, setSubTotal] = useState(0);
//  console.log(userId);
  const navigate = useNavigate();
  const indexPath = '/';
  
  useEffect(() => {
    if (userId == 0 ) {
      navigate(indexPath);
    }
    
  }, [userId, navigate]);


  const [orderData, setOrderData] = useState([]); // Initialize as an empty array

 // console.log(orderData);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response;
        if (userType == 1) {
          response = await axios.get('https://projects.sninfoserv.com/fbh-api/dine/orders/');
        } else if (userType == 2) {
          response = await axios.post('https://projects.sninfoserv.com/fbh-api/dine/orders/', { "userId":userId });
        }
        const orderDate = new Date(currentDateTime);

  // Format the date to dd/mm/yyyy
  const day = String(orderDate.getDate()).padStart(2, '0');
  const month = String(orderDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const year = orderDate.getFullYear();
  const formattedDate = `${year}/${month}/${day}`;
         const filteredOrder = response.data.data.filter(order => order.order_date === formattedDate);
        setOrderData(filteredOrder);
        console.log(filteredOrder);
      } catch (error) {
        // Handle error if needed
      }
    };
  
    fetchData();
  
    const intervalId = setInterval(() => {
      fetchData();
    }, 1000);
  
    return () => clearInterval(intervalId);
  }, [userType, userId]); // Add dependencies that are used inside the effect
  
  // Render your component based on the orderData state



    const popupStyle = {
        content: {
          margin: 'auto',
          background: 'rgb(255, 255, 255)',
          width: '20%',
          padding: '5px',
        },
        arrow: {
          color: '#6c757d',
        },
        tooltip: {
          width: '200px',
          boxShadow: '#333',
        },
        overlay: {
          background: 'rgba(0, 0, 0, 0.5)',
        },
      };

      const handleClick = async (id) => {
       // console.log(`Button with id ${id} clicked`);
        const orderId = {"order_id": id};
      
        try {
          const response = await axios.post('https://projects.sninfoserv.com/fbh-api/dine/update/', orderId);
      
          if (response.data.status === 'success') {
          //  console.log('Status Updated!');
         // stock(id);
          }else{
          //  console.log(response.error.message);
          }
      
        } catch (error) {
          console.error('Update failed!', error);
        }
      };


      



      const handleClickdeliverystatus = async (id, area) => {
        // console.log(`Button with id ${id} clicked`);
         const formData = {
          orderid:id,
          area:area,
         };
     //  console.log(formData);
         try {
           const response = await axios.post('https://projects.sninfoserv.com/fbh-api/dine/update/', formData);
       
           if (response.data.status === 'success') {
           //  console.log('Status Updated!');
          // stock(id);
           }else{
           //  console.log(response.error.message);
           }
       
         } catch (error) {
           console.error('Update failed!', error);
         }
       };




      const stock= async(orderId)=>{
       // console.log(orderId);
          try {
       
              const response = await axios.get('https://projects.sninfoserv.com/fbh-api/dine/orders/');
              const filtereddata =response.data.data.filter(order => order.order_id === orderId);
           // console.log(filtereddata);
             const order_details=filtereddata[0].order_item_details;
           //  console.log( order_details);
             
             const mappedDetails = order_details.map(item => {
              return {
                  itemName: item.order_item, // Adjust this based on your actual data structure
                  itemQuantity: item.order_qty, // Adjust this based on your actual data structure
              };
          });

         // console.log(mappedDetails);
          const responses = await axios.post('https://projects.sninfoserv.com/fbh-api/stock/', {
            mappedDetails
        });
            // console.log(responses.data);
          } catch (error) {
            // Handle error if needed
          }
      
  
}
useEffect(() => {
  stock();
}, []);
       // Function to handle printing
       const handlePrint = (content) => {
        const iframe = document.createElement('iframe');
        iframe.setAttribute('style', 'display: none;');
        document.body.appendChild(iframe);
        const iframeDoc = iframe.contentWindow.document;
        iframeDoc.open();
        iframeDoc.write(content);
        iframeDoc.close();
        iframe.contentWindow.print();
      };

      
    
  


  
  // Function to generate HTML content for the table
  const generateTableContent = (order) => {
    
  


const isDiscountPercentage = order.order_discount.includes('%'); 

let discountedAmount;
const discValue = parseFloat(order.order_discount.replace('%', ''));

if (isDiscountPercentage) {
  discountedAmount = Number( order.order_amount * (discValue / 100) );  
} else {
  discountedAmount = Number(discValue);  
}

//console.log('Discount = ', discountedAmount);



const totalprice = (price) => {
  let pPrice = 0; 

  if (!isNaN(price) && price > 0) {  
    const gst = 5;
    pPrice = price/(1+(gst/100));
  }else{
    pPrice = 0;
  }
  
  return pPrice;  
};

const gst = 5;
let subtotal = 0;
order.order_item_details.forEach(orderdetail => {
  subtotal += ((orderdetail.order_price/(1+(gst/100))) *  orderdetail.order_qty);
  //console.log(subtotal);
})
setSubTotal(subtotal);




  // Convert the order's date string to a Date object
  const orderDate = new Date(order.order_date);

  // Format the date to dd/mm/yyyy
  const day = String(orderDate.getDate()).padStart(2, '0');
  const month = String(orderDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const year = orderDate.getFullYear();
  const formattedDate = `${day}/${month}/${year}`;
 




const cgst = subtotal * (2.5 / 100);
const sgst = subtotal * (2.5 / 100);
const discountedAmounts = parseFloat(discountedAmount) || 0;
const gTotal = Number(order.order_amount) - Number(discountedAmounts) + Number(order.order_delivery);
   

const discountRow = discountedAmount > 0 ? `
    <tr align="center">
      <td colspan="3" class="content right" width="10%">Discount</td>
      <td class="content right" width="10%">₹${Number(discountedAmount).toFixed(2)}</td>
    </tr>` : '';

  
    const deliveryRow = order.order_delivery > 0 ? `
    <tr align="center">
      <td colspan="3" class="content right" width="10%">Delivery</td>
      <td class="content right" width="10%">₹${Number(order.order_delivery).toFixed(2)}</td>
    </tr>` : '';
    

    const address = (order.order_area === 'Delivery') ? `
    <div class="address1">${order.order_address}</div>` : '';

    const token = (order.order_area === 'Delivery' || order.order_area === 'TakeAway') ? `
    <div class="header">Token Number :${order.order_token}</div>` : '';

    const name = (order.order_area === 'Delivery' || order.order_area === 'TakeAway') ? `
   <div class="address1">${order.order_name}</div>` : '';

   const phone = (order.order_area === 'Delivery' || order.order_area === 'TakeAway') ? `
   <div class="address1">${order.order_phone}</div>` : '';

    

    return `
      <html>
      <head>
       
         <style>
             
              body {
                margin: 0px; 
              }
              .header {
                font-size: 18px;
                font-weight: bold;
                text-align: center;
                margin-bottom: 5px; 
              }
              .address {
                font-size: 12px;
                font-weight: bold;
                text-align: center;
                margin-bottom: 0px; 
              }
              .content {
                font-size: 14px;
                font-weight: bold;
                margin-bottom: 10px;
              }
              .left {
                text-align:left;
              }
              .right{
                text-align:right;
              }
            </style>
      </head>
      <body>
      <div style="display: flex; justify-content: center;">
      <img  src="${logo}" width="193" height="60" />
  </div>
      
       <div class="address">+91 82202 45554</div>
      <div class="address">GSTIN :33DAGPP5227K2ZH</div><br>
        <hr></hr>
        ${name}
        ${phone}
        ${address}
       <div  class="address"></div>
      <hr></hr>
       <div class="address1">${order.order_area}</div>
       <div class="address1">Date:${formattedDate}</div>
        <div class="address1">Bill No : #${order.order_id}</div>
          ${token}
    
         <hr></hr>
        <table align="center" width="100%">
          <thead>
    <tr class="borders">
     <th class="content left" width="70%">Item</th>
       <th class="content middle" width="10%">Price</th>
      <th class="content middle" width="10%">Qty</th>
      <th class="content right" width="20%">Amount</th>
    </tr>
  </thead>
            <tbody>
            ${order.order_item_details.map((orderDetails) =>`
               <tr align="center">
                <td class="content left" width="70%">${orderDetails.order_item}</td>
                 <td class="content middle" width="10%">${Number(orderDetails.order_price).toFixed(2)}</td>
                 <td class="content middle" width="10%">${orderDetails.order_qty}</td>
                 <td class="content right" width="20%">₹${Number(Number(orderDetails.order_price) * Number(orderDetails.order_qty)).toFixed(2)}
           </td>
              </tr>`
            )}
          </tbody>
           <tfoot style="margin-top:10px;">


            <tr align="center">
            <td colspan="3"  class="content right" width="10%"></td>
            <td class="content right" width="10%"></td>
          </tr>


          
            <tr align="center">
            <td colspan="3"  class="content right" width="10%"></td>
            <td class="content right" width="10%"></td>
          </tr>


         <tr align="center">
            <td colspan="3"  class="content right" width="10%">Subtotal</td>
             <td class="content right" width="10%">₹${Number(order.order_amount).toFixed(2)}</td>
          </tr>


          

             ${discountRow}

            ${deliveryRow}
            

           <tr align="center">
            <td colspan="3"  class="content right" width="10%">CGST (2.5%):</td>
            <td class="content right" width="10%">₹${Number(cgst).toFixed(2)}</td>
          </tr>

           <tr align="center">
            <td colspan="3"  class="content right" width="10%">SGST (2.5%):</td>
            <td class="content right" width="10%">₹${Number(sgst).toFixed(2)}</td>
          </tr>

           <tr align="center">
            <td colspan="3"  class="content right" width="10%">Grand Total:</td>
            <td class="content right" width="10%"><b>₹${Number(gTotal).toFixed(2)}</b></td>
          </tr><br>

            
           
        
        </tfoot>
         
      </table><br>

      
    
    
    

      <div  class="address"></div>
     <hr></hr>
      
      <div class="address">FSSAI LIC NO:12420003001528</div>
     
    </body>
      </html>
    `;
  };
  

  return (
    

    <div  id="mainDiv">
    { userType === '1' ?(
    <SideBar />
      ) : (< EsideBar />
     )}

    <main class="main-content position-relative border-radius-lg hidden-for-print1">
    <div class="min-height-300 bg-primary position-absolute w-100"></div>
    <NavBar />
   
    <div class="container-fluid py-4">
    <div class="row">
        <div class="col-12">
         
            <div class="card-header pb-0">
              <h6>Dine In</h6>
            </div>
            <div class="card-body px-1 pt-1 pb-2">
              <div class="row">
              {Array.isArray(orderData) && orderData.map(orders => (

                <div class="col-lg-3 col-md-12 col-12 mb-4">
                <div class="card" >
                  <div class="card-body" style={{minHeight:'350px'}}>
                    <div class="card-title d-flex align-items-start justify-content-between">


     
      
  
                      <h6>{orders.order_area}</h6>
                      <Popup trigger={<button class="btn btn-primary btn-sm ms-auto">Items - {orders.order_items}</button>} >
                         <div class="col-lg-12">
                          <div class="card h-100">
                           <div class="card-header pb-0 p-3">
                            <div class="row">
                             <div class="col-12 d-flex align-items-center">
                              <h6 class="mb-0 text-primary">Order Details</h6>
                             </div>
                             
                            </div>
                           </div>
                           <div class="card-body p-3 pb-0">
                            <ul class="list-group">

                            {orders.order_item_details.map(orderDetails => (

                             <li class="border-0 d-flex justify-content-between ps-0 mb-0 border-radius-lg">
                              <div class="d-flex flex-column">
                               <h6 class="mb-1 text-dark font-weight-bold text-sm py-2">{orderDetails.order_item}  <span class="text-secondary"> x {orderDetails.order_qty}</span></h6>
                              </div>
                              
                              
                             </li>

                             ))}
               
                            </ul>
                           </div>
                          </div>
                         </div>
                       </Popup>
                    </div>  
                    <span class="fw-semibold d-block mb-1"> {orders.order_id}</span>
                    {orders.order_area === "Outer_Table" ? (
  <>
    <span className="fw-semibold d-block mb-1">
      {orders.order_table}:
   
    <span className="fw-small d-block mb-1" style={{ fontSize: '0.8rem' }}>
      {orders.order_chair}
    </span>
    </span>
  </>
) : (
  <>
    <span className="fw-small d-block mb-1" style={{ fontSize: '0.8rem' }}>
      {orders.order_chair}
    </span>
  </>
)}

                    {orders.order_area ==="Delivery"?(<>
                      <h3 class="card-title mb-2">₹ {numeral(Number(orders.order_amount) + Number(orders.order_delivery)).format('0,0.00')}
                      </h3>

 </>):(
                      <>
                      <h3 class="card-title mb-2">₹ {numeral(orders.order_amount).format('0,0.00')}</h3>
                    </>
                    ) }


{orders.order_area === "Delivery" ? (
  <>
    <div className="d-flex justify-content-between align-items-center">
      <small className="text-success fw-semibold">
        <i className="bx bx-up-arrow-alt"></i>{orders.order_s_time} ({orders.order_timing})
      </small>
      <div className="text-align-center">
        {orders.order_status === 'Started' ? (
         <button 
         className="btn btn-warning" 
         style={{ 
           width: '40px',       // Adjust width to control the size of the circle
           height: '40px',      // Set height equal to width for a perfect circle
           borderRadius: '50%'  // Make it round
         }} 
         onClick={() => handleClick(orders.order_id)}
       > 
        <i
       className="fas fa-off text-center text-lg opacity-10"
       style={{ color: 'white', position: 'relative', top: '1px', right:'8px' }}
     ></i>
       </button>
        ) : (
          <button className="btn btn-success"
          style={{ 
            width: '40px',       // Adjust width to control the size of the circle
            height: '40px',      // Set height equal to width for a perfect circle
            borderRadius: '50%'  // Make it round
          }} >
          <i
       className="fas fa-check-circle text-center text-lg opacity-10"
       style={{ color: 'white', position: 'relative', top: '1px', right:'8px' }}
     ></i>
          </button>
        )}
      </div>
    </div>
  </>
)
 : orders.order_area === "Outer_Table" || orders.order_area ==="Floor" ? (
  <>
     <>
     <div className="d-flex justify-content-between align-items-center">
      <small className="text-success fw-semibold">
        <i className="bx bx-up-arrow-alt"></i>{orders.order_s_time} ({orders.order_timing})
      </small>
      </div>
  </>
  </>
) : (
  <>
  </>
)}


{orders.order_area ==="Outer_Table"?(<>
                      <div class="mt-2 text-align-center" align="center">
                    {orders.order_status === 'Started' ? (
                         <button className="btn btn-warning btn-sm ms-auto" onClick={() => handleClick(orders.order_id)}>
                           {orders.order_status}
                         </button>
                       ) : (
                         <button className="btn btn-success btn-sm ms-auto" onClick={() => handlePrint(generateTableContent(orders))}>
                           Print Bill
                         </button>
                      )}
                      </div>
                      </>):(<>
                        <div class="mt-2 text-align-center" align="center">
                          <button className="btn btn-success btn-sm ms-auto" onClick={() => handlePrint(generateTableContent(orders))}>
                           Print Bill
                         </button>
                         </div></>)}

                        
                   
<div className="text-align-center">
       
       <a 
       className="btn btn-secondary" 
       style={{ 
         width: '40px',       // Adjust width to control the size of the circle
         height: '40px',      // Set height equal to width for a perfect circle
         borderRadius: '50%',
         float:'right',
         position: 'relative',
         top: '-50px',
         left:'10px' // Make it round
       }} 
       href={`editbill?id=${orders.order_id}`}
     > 
      <i
     className="fas fa-edit text-center text-sm opacity-10"
     style={{ color: 'white', position: 'relative', top: '0px', right:'7px' }}
   ></i>
     </a>
      
    </div>
     
                  </div>
                  
                </div>
              </div>
                    
                 ))}
              </div>
            </div>
          </div>
        </div>
      <Footer />
    </div>
  </main>

  </div>

  
  )
}
export default App;
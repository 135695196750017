import React, { useEffect, useState } from 'react';
import { ReactSession }  from 'react-client-session';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import SideBar from './sidebar';
import EsideBar from './esidebar';
import NavBar from './navbar';
import Footer from './footer';
import numeral from 'numeral';
import './test.css';
import logo from '../images/logo_full.png';
ReactSession.setStoreType("localStorage");


function App() {
  
  const navigate = useNavigate();
  const userId = ReactSession.get("userId");
  const userType = ReactSession.get("userType");
  const userName = ReactSession.get("userName");
  const indexPath = '/';

  useEffect(() => {
    if (userId == 0 ) {
      navigate(indexPath);
    }
  }, [userId, navigate]);

  const [product, setproduct]=useState([]);
  const [stocks, setstocks]=useState('');
  const [qty, setQty] = useState('0');
  const [orderId, setorderId] = useState('');    
  const [orderData, setOrderData] = useState(true);
  const [discount1Checked, setDiscount1Checked] = useState(false);
  const [discount2Checked, setDiscount2Checked] = useState(false);
  const [discount3Checked, setDiscount3Checked] = useState(false);
  const [discount4Checked, setDiscount4Checked] = useState(false);
  const [isDiscountVisible, setIsDiscountVisible] = useState(true);
  const [discount, setDiscount] = useState('');

  const [acHallData, setacHallData] = useState(true);
  const [hutData, setHutData] = useState(true);
  const [rfTableData, setrfTableData] = useState(true);
  const [outerData, setouterData] = useState(true);
  const [datas, setData] = useState(true);

  const getToken = async () => {
    try {
      const response = await axios.get('https://projects.sninfoserv.com/fbh-api/token/');
   //   console.log(response.data);
      if (response.data.status === 'success') {
        const token = response.data.token;
        setToken(token);   
      }else{
        console.error(response.data);
      }
    } catch (error) {
      console.error('Error fetching token:', error);
    }
  };

  const fetchProData = async () => {
    try {
      const response = await axios.get('https://projects.sninfoserv.com/fbh-api/products/');
      if(response.data.status === "success"){
        setproduct(response.data);
      }else{
        console.error(response.data);
      }
    } catch (error) {
      console.error('There was a problem with login:', error);
      setError('Invalid email or password');
    }
  };




  const fetchOuterData = async () => {
    try {
      const response = await axios.get('https://projects.sninfoserv.com/fbh-api/dine/outer/');
      if(response.data.status === 'success'){
        setouterData(response.data);
      }else{
        console.error(response.data);
      }
    } catch (error) {
        console.error(error);
    } 
  };



  useEffect(()=>{
    getToken();
    fetchOuterData();
    fetchProData();
  }, []);


  // Handle checkbox change
  const handleCheckboxChange1 = (e) => {
    setDiscount1Checked(e.target.checked);
  };

  const handleCheckboxChange2 = (e) => {
    setDiscount2Checked(e.target.checked);
  };

  const handleRadioChange3 = (e) => {
    setDiscount3Checked(e.target.checked);
    setDiscount4Checked(false); // Ensure discount 4 is unchecked when discount 3 is selected
  };

  // Handle radio button for discount 4 (flat amount)
  const handleRadioChange4 = (e) => {
    setDiscount4Checked(e.target.checked);
    setDiscount3Checked(false); // Ensure discount 3 is unchecked when discount 4 is selected
  };


  const handledisount = (e) => {
    let value = e.target.value;
    
    // Remove any non-numeric characters and remove any % that might be in between digits
    value = value.replace(/[^0-9]/g, ''); // Remove all non-numeric characters
  
    // If checkbox 3 is checked, append '%' to the value
    if (discount3Checked) {
      value = value.replace(/%$/, '');  // Remove any trailing '%' if it's already there
      value += '%';  // Add '%' to the end of the value
      setDiscount(value);  // Set the discount with '%'
    } else {
      setDiscount(value);  // Set the discount without '%'
    }
  };
  
 

  useEffect(() => {
    if (discount === '0') {
      setIsDiscountVisible(false); // Hide the discount row if discount is 0
    } else {
      setIsDiscountVisible(true); // Show the discount row if discount is not 0
    }
  }, [discount]);
 

  
  const [formError, setError]=useState({});
  const [orders, setOrders] = useState([]);
  const [section, setHut] = useState('');
  const [item, setItem] = useState('');
  const [price, setPrice] = useState('');

  
  const [area, setArea] = useState('TakeAway');
  const [user, setUser] = useState(userId);
  const [nextId, setNextId] = useState(1);
  const [amount, setAmount] = useState(0);
 //  console.log(amount);
  const [cName, setcName] = useState('');
  const [cPhone, setcPhone] = useState('');
  const [cAddress, setcAddress] = useState('');
  const [chair, setChair] = useState('');
  const [cdelivery, setdelivery] = useState('');

  const [token, setToken] = useState("");
  const [currentDate, setCurrentDate] = useState("");
  const [desc, setdesc] = useState("");
  const [subTotal, setSubTotal] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);



  const handlechangephonenumber= async (value)=>{
    try {
      let response;
      const formdata ={
        phonenumber:value
      }
      console.log(formdata);
      response = await axios.get('https://projects.sninfoserv.com/fbh-api/customerdetails/', {
        params: formdata  
    });

      // console.log(response.data);
      
      if(response.data.count > 0){
        setcPhone(response.data.data[0].phone);
        setcName(response.data.data[0].section);
        setcAddress(response.data.data[0].address);
      }else {
        // If no matching order is found, reset phone number to the current one
        setcPhone(value); // Optional: can be omitted as cPhone is already the current value
        setcName("");
        setcAddress("");
      }
      

    // console.log(filteredOrder);
    } catch (error) {
      // Handle error if needed
    }

  }


  useEffect(() => {
    if(orders.length > 0){
      const gst = 5;
      let subtotal = 0;
      let gTotal = 0;
      orders.forEach(order => {
        subtotal += ((order.price/(1+(gst/100))) *  order.qty);
        gTotal += (order.price * order.qty);
      })
      setSubTotal(subtotal);
      setGrandTotal(gTotal);
    }
  },[orders]);


  const handleSelectAllToggle = () => {
    if (chair.length === 4) {
      setChair([]);
    }else if (chair.length === 6) {
      setChair([]);
    } else if (section === 'Table-4') {
      setChair(['chair-1', 'chair-2', 'chair-3', 'chair-4', 'chair-5', 'chair-6']);
    } else {
      setChair(['chair-1', 'chair-2', 'chair-3', 'chair-4']);
    }
  };


  const handleChairToggle = (chairId) => {
    setChair(prevChairs => {
      if (prevChairs.includes(chairId)) {
        return prevChairs.filter(item => item !== chairId); 
      } else {
        return [...prevChairs, chairId]; 
      }
    });
  };
  
  
  
  const handleLinkClick = (newValue) => {
    setArea(newValue);
    setAmount(0);
  };
  
  const itemChange = (e) => {
    setItem(e.target.value);
    setPrice('');
    setQty('');
    checkProduct(e.target.value);
  };


  
  
  const checkProduct = (Currentitem) => {
    // console.log("Current item:", Currentitem); // Log the current item
    // console.log("Product data:", product?.data); // Log product data
  
    const foundProducts = product?.data?.filter(prod => prod.cat === Currentitem);
    // console.log("Found products:", foundProducts); // Log found products
  
    if (foundProducts && foundProducts.length > 0) {
      foundProducts.forEach(foundProduct => {
        if (parseInt(foundProduct.stock) > 0) {
         // alert(`${foundProduct.cat} ${foundProduct.stock} is in stock.`);
        } else {
          //alert(`${foundProduct.cat} is out of stock.`);
        }
      });
    } else {
    //  alert("Product not found."); // Uncomment this to alert if no products found
    }
  };
  
  
  const checkProductqty = (Currentitem, qtys) => {
    // console.log("Current item:", Currentitem); // Log the current item
    // console.log("Product data:", product?.data); // Log product data
    
    // Find the product by category (Currentitem)
    const foundProducts = product?.data?.filter(prod => prod.cat === Currentitem);
   // console.log("Found products:", foundProducts); // Log found products
    
    // If product is found
    if (foundProducts && foundProducts.length > 0) {
      const productStock = foundProducts[0]?.stock;
      setstocks(productStock);
     // console.log("Product stock:", productStock); 
     // console.log("Requested qty:", qtys); 
      
      if (Number(qtys) <= Number(productStock)) {
        // If stock is sufficient, proceed to add to cart
       alert(`${foundProducts[0]?.cat} - ${productStock} items are available in stock.`);
  
       
        // Reduce stock by the ordered quantity
      } else {
        if(productStock <= 0){
          alert(`${foundProducts[0]?.cat} out of stock.`);
        }else{
          alert(`${foundProducts[0]?.cat} - Only ${productStock} items are available in stock. Please reduce the quantity. ${qtys}`);
        
        }
        // If stock is insufficient, show an alert
         }
    } else {
      // If no products were found for the given category
     // alert("Product not found.");
    }
  };
  
  

  const qtyChange = (e) => {
    setQty(e.target.value);
      fetchPrice();
   // checkProductqty(item, e.target.value);
  };
  
  
  const fetchPrice = () => {
     // Refresh the page after printing
    const productId = product?.data.find(items => items.cat === item);
    // console.log("productId",productId);
    if (productId) {
      setPrice(productId?.price);
    } else {
      setPrice(null);
      // console.log('Open Product');
    }
    
    setAmount(amount + (price * qty));
  };

  
  const additem = () => {


    
    
    //alert(qty +' - '+stocks);
    // if (Number(qty) > Number(stocks) ) {
      //alert("Not enough stock available for the requested quantity.");
      // Prevent the default form submission behavior
     // event.preventDefault();

      // setHut(section);
      // setItem('');
      // setPrice('');
      // setQty('');
      // setArea(area); // This seems redundant, but keeping it for clarity
      // setUser(user); // This is also redundant if already set elsewhere
      // Update the total amount after the order is added
    // } else {
     // event.preventDefault();
  
      let newOrder;  
      const dt = new Date();
      const time = userId + dt.getFullYear().toString() + (dt.getMonth() + 1).toString().padStart(2, '0') + dt.getDate().toString().padStart(2, '0') + dt.getHours().toString().padStart(2, '0') + dt.getMinutes().toString().padStart(2, '0') + dt.getSeconds().toString().padStart(2, '0');
      const orderId = time;
      setorderId(orderId);

      if (area === 'Outer_Table' || area === 'Floor') {
          newOrder = {
              id: nextId,
              user: userId,
              area: area,
              orderId:orderId,
              section: section,
              item: item,
              price: price,
              qty: qty,
              cName: cName,
              cPhone: cPhone,
              cAddress: cAddress,
              amount: amount,
              chair: chair,
          };
      } else {
        
          newOrder = {
              id: nextId,
              user: userId,
              orderId:orderId,
              area: area,
              section: section,
              item: item,
              price: price,
              qty: qty,
              cName: cName,
              cPhone: cPhone,
              cAddress: cAddress,
              amount: amount,
              delivery:cdelivery,
              discount:discount,
              desc:desc
             
          };
      }
      
      // Now you can use `newOrder` here after the if-else block
      
  
      // Update the orders array with the new order
      setOrders([...orders, newOrder]);
  
      // Increment the nextId for the next order
      setNextId(nextId + 1);
  
      // Reset values after order submission
      setHut(section);
      setItem('');
      setPrice('');
      setQty('');
      setArea(area); // This seems redundant, but keeping it for clarity
      setUser(user); // This is also redundant if already set elsewhere
      setAmount(amount + (qty * price)); // Update the total amount after the order is added
    // }
  };
  


  // console.log(orders);
 


  const handlestock = async () => {
    const orderData = {orderData: orders, token: token}

    try {
      const response = await axios.post('https://projects.sninfoserv.com/fbh-api/order/', orderData);
     //console.log(orders);
     console.log(response.data);
      if(response.data.status === 'success' ){
        getToken();
        console.log("fetched token");
     
        handlePrint(generateTableContent());
        console.log("generated");
        handleDeleteAll();
      }else{
         console.log(response.data.error.message);
      }
      setError(validate(response));
      } catch (error) {
        console.error('API Issue', error);
      }
  };


  const stock = async () => {
    try {
        const mappedDetails = orders.map((order) => {
            return {
                itemName: order.item, // Adjust this based on your actual data structure
                itemQuantity: order.qty // Adjust this based on your actual data structure
            };
        });

        // console.log(mappedDetails);

        const response = await axios.post('https://projects.sninfoserv.com/fbh-api/stock/', {
            mappedDetails
           
        });
        setAmount(0);
      //  console.log(response.data);
    } catch (error) {
        console.error('Error:', error);
        // Handle error if needed
    }
};






useEffect(() => {
  const fetchData = async () => {
    try {
      let response;
      if (userType == 1) {
        response = await axios.get('https://projects.sninfoserv.com/fbh-api/dine/orders/');
      } else if (userType == 2) {
        response = await axios.post('https://projects.sninfoserv.com/fbh-api/dine/orders/', { "userId":userId });
      }
      setOrderData(response.data);
       // console.log(response.data);
    } catch (error) {
      // Handle error if needed
    }
  };

  fetchData();

  const intervalId = setInterval(() => {
    fetchData();
  }, 1000);

  return () => clearInterval(intervalId);
}, [userType, userId]);


  const handleOrder = async () => {
    const orderData = {orderData: orders}
    try {
      const response = await axios.post('https://projects.sninfoserv.com/fbh-api/order/', orderData);
      console.log(response.data);

      if(response.data.status === 'success' ){
        getToken();
        handlePrint(generateTableContent());
        handleDeleteAll();
        if(area === "Take Away" ||area === "Delivery"){
          setAmount(0);
          setcAddress("");
          setcPhone("");
          setcName("");
          setGrandTotal(0);
        }
      }else{
        console.log(response.data.error.message);
        setError(validate(response));
      }
      
    } catch (error) {
      console.error('Login failed!', error);
    }
    
  };

  const validate=(response)=>{
    const errors={};
      if(response.data.status === 'success'){
        errors.name = response.data.status;
        return errors;
      }else{
        errors.name = response.data.error.message;
        return errors;
      }
      
  }

  useEffect(()=>{
   // console.log(formError);
    if(Object.keys(formError).length ===0 & handleOrder){
      
    }
  });
 
  const handleDelete = (id) => {
    const updatedOrders = orders.filter((order) => order.id !== id);
    setOrders(updatedOrders);
  };
  
  const handleDeleteAll = () => {
    setOrders([]);
    setAmount(0);
    setcAddress("");
    setcPhone("");
    setcName("");
    setHut("");
    setChair([]);
    setGrandTotal(0);
    getToken();
  };

  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  
    useEffect(() => {
      const intervalId = setInterval(() => {
        setCurrentDateTime(new Date());
      }, 1000);
  
      return () => clearInterval(intervalId);
    }, []); 
  
    const formattedDateTime = currentDateTime.toLocaleString();
  

  const handlePrint = (content) => {
    const iframe = document.createElement('iframe');
    iframe.setAttribute('style', 'display: none;');
    document.body.appendChild(iframe);
    const iframeDoc = iframe.contentWindow.document;
    iframeDoc.open();
    iframeDoc.write(content);
    iframeDoc.close();
    iframe.contentWindow.print();
  };

  const generateTableContent = () => {
   
    
    const isDiscountPercentage = discount.includes('%'); 

    let discountedAmount;
    const discValue = parseFloat(discount.replace('%', ''));
    
    if (isDiscountPercentage) {
      discountedAmount = Number( grandTotal * (discValue / 100) );  
    } else {
      discountedAmount = Number(discValue);  
    }


    const totalprice = (price) => {
      let pPrice = 0; 
    
      if (!isNaN(price) && price > 0) {  
        const gst = 5;
        pPrice = price/(1+(gst/100));
      }else{
        pPrice = 0;
      }
      
      return pPrice;  
    };

    const cgst = subTotal * (2.5 / 100);
    const sgst = subTotal * (2.5 / 100);
    const discountedAmounts = parseFloat(discountedAmount) || 0;
    const gTotal = Number(grandTotal) - Number(discountedAmounts) + Number(cdelivery);
  


    const discountRow = discountedAmount > 0 ? `
    <tr align="center">
      <td colspan="3" class="content right" width="10%">Discount</td>
      <td class="content right" width="10%">₹ ${Number(discount).toFixed(2)}</td>
    </tr>` : '';

  
    const deliveryRow = cdelivery > 0 ? `
    <tr align="center">
      <td colspan="3" class="content right" width="10%">Delivery</td>
      <td class="content right" width="10%">₹ ${Number(cdelivery).toFixed(2)}</td>
    </tr>` : '';


    const note = (area === 'Delivery' && desc  || area === 'TakeAway' && desc) ? `
    <div id="addressDiv" class="address" style="border: 2px solid #000; padding: 10px; background-color: #f9f9f9; border-radius: 5px;">
       <h3>Note: ${desc}</h3>
    </div><br>` : '';
   
 
    const address = (area === 'Delivery') ? `
    <div class="address1">Address : ${cAddress}</div>` : '';
   

   // console.log(gTotal);
    if (area === 'TakeAway' || area === 'Delivery') {
      return `
      <html>
    <head>
      <title>Order Details</title>
      <link rel="stylesheet" href="styles.css">
        <title>Order Details</title>
        <style>
             
              body {
                margin: 0px; 
              }
              .header {
                font-size: 18px;
                font-weight: bold;
                text-align: center;
                margin-bottom: 5px; 
              }
              .address {
                font-size: 12px;
                font-weight: bold;
                text-align: center;
                margin-bottom: 0px; 
              }
              .content {
                font-size: 14px;
                font-weight: bold;
                margin-bottom: 10px;
              }
              .left {
                text-align:left;
              }
              .right{
                text-align:right;
              }
                @media print {
             .page-break {
               page-break-before: always; 
               }
              }

            </style>
    </head>
    <body>


     <!-- Order Header and Address -->
      <div class="header">KOT-${userName}</div>
      <div class="address">${formattedDateTime}</div><br>
      <div class="address"><h2 style="font-size: 16px">${orders[0].cName}</h2></div>

      <table align="center">
        ${orders.map(order => `
          <tr >
            <td class="content left" width="85%" style="font-size: 16px">${order.item}</td>
            <td class="content right"  style="font-size: 16px">${order.qty}</td>
          </tr>`).join('')}
      </table><br>
      

       
      <div class="address">${area + ', ' + section}</div><br>
       ${note}

        <!-- token -->
      <div class="header">Token : ${token}</div>
       
      <br></br>



<!-- Page Break -->
<div class="page-break"></div>

       <!-- Resto Control Info and Pricing customer -->
       <div style="display: flex; justify-content: center; align-items: center;">
      <img  src="${logo}" width="193" height="60" />
  </div><br>
      <div class="address">+91 82202 45554</div><br>


     

        <!-- token -->
    <div class="header">Token : ${token}</div>
    ${note}
      <div class="address"><h3>Grand Total : ₹ ${numeral(gTotal).format('0,0.00')}</h3></div><br>

      

      <br></br>
      
<!-- Page Break -->
<div class="page-break"></div>




      <!-- Resto Control Info and Pricing -->
       <div style="display: flex; justify-content: center; align-items: center;">
       <img  src="${logo}" width="193" height="60" />
  </div><br>
    
       <div class="address">+91 82202 45554</div>
      <div class="address">GSTIN :33DAGPP5227K2ZH</div><br>

      <hr></hr>
 <div class="address1">${orders[0].cName}</div>
 <div class="address1">${orders[0].cPhone}</div>
        ${address}
       <div  class="address"></div><br>
      <hr></hr>
      <div class="address1">${area + ', ' + section}</div>
       <div class="address1">Date : ${formattedDateTime}</div>
       <div class="address1">Bill No : #${orderId}</div>
        <div class="header">Token Number : ${token}</div>
  
<hr></hr>
      <table align="center" width="100%">
          <thead>
    <tr class="borders">
      <th class="content left" width="70%">Item</th>
       <th class="content middle" width="10%">Price</th>
      <th class="content middle" width="10%">Qty</th>
      <th class="content middle" width="10%">Amount</th>
    </tr>
  </thead>
            <tbody>
        ${orders.map(order => `
          <tr align="center">
         
            <td class="content left" width="70%">${order.item}</td>
            <td class="content middle" width="10%">${Number(order.price).toFixed(2)}</td>
            <td class="content middle" width="10%">${order.qty}</td>
            <td class="content right" width="20%">₹${Number(Number(order.price) * Number(order.qty)).toFixed(2)}</td>

          </tr>`).join('')}
            </tbody>
           <tfoot>

            <tr align="center">
            <td colspan="3"  class="content right" width="10%"></td>
            <td class="content right" width="10%"></td>
          </tr>


          
            <tr align="center">
            <td colspan="3"  class="content right" width="10%"></td>
            <td class="content right" width="10%"></td>
          </tr>
  
         <tr align="center">
            <td colspan="3"  class="content right" width="10%">Sub Total</td>
            <td class="content right" width="10%">₹ ${Number(gTotal).toFixed(2)}</td>
          </tr>


           ${discountRow}

            ${deliveryRow}


           <tr align="center">
            <td colspan="3"  class="content right" width="10%">CGST (2.5%):</td>
            <td class="content right" width="10%">₹ ${Number(cgst).toFixed(2)}</td>
          </tr>

           <tr align="center">
            <td colspan="3"  class="content right" width="10%">CGST (2.5%):</td>
            <td class="content right" width="10%">₹ ${Number(sgst).toFixed(2)}</td>
          </tr>

           <tr align="center">
            <td colspan="3"  class="content right" width="10%">Grand Total:</td>
            <td class="content right" width="10%"><b>₹ ${Number(gTotal).toFixed(2)}</b></td>
          </tr><br>



         
        
        </tfoot>
         
      </table><br>
     
     
   

      <div  class="address"></div>
     <hr></hr>
      
      <div class="address">FSSAI LIC NO:12420003001528</div>
     
    </body>
  </html>
    `;
    
    }else if (area === 'Floor') {
      return `
      <html>
    <head>
      <title>Order Details</title>
      <link rel="stylesheet" href="styles.css">
        <title>Order Details</title>
        <style>
             
              body {
                margin: 0px; 
              }
              .header {
                font-size: 18px;
                font-weight: bold;
                text-align: center;
                margin-bottom: 5px; 
              }
              .address {
                font-size: 12px;
                font-weight: bold;
                text-align: center;
                margin-bottom: 0px; 
              }
              .content {
                font-size: 14px;
                font-weight: bold;
                margin-bottom: 10px;
              }
              .left {
                text-align:left;
              }
              .right{
                text-align:right;
              }
                @media print {
             .page-break {
               page-break-before: always; 
               }
              }

            </style>
    </head>
    
    <body>

        <!-- Resto Control Info and Pricing -->
       <div style="display: flex; justify-content: center; align-items: center;">
       <img  src="${logo}" width="193" height="60" />
  </div><br>
    
       <div class="address">+91 82202 45554</div>
      <div class="address">GSTIN :33DAGPP5227K2ZH</div><br>

     
      <hr></hr>
      <div class="address1">${area + ', ' + section}</div>
       <div class="address1">Date:${formattedDateTime}</div>
       <div class="address1">Bill No : #${orderId}</div>
     
  
<hr></hr>
      <table align="center" width="100%">
          <thead>
    <tr class="borders">
      <th class="content left" width="70%">Item</th>
       <th class="content middle" width="10%">Price</th>
      <th class="content middle" width="10%">Qty</th>
      <th class="content middle" width="10%">Amount</th>
    </tr>
  </thead>
            <tbody>
        ${orders.map(order => `
          <tr align="center">
         
            <td class="content left" width="70%">${order.item}</td>
            <td class="content middle" width="10%">${Number(order.price).toFixed(2)}</td>
            <td class="content middle" width="10%">${order.qty}</td>
            <td class="content right" width="20%">₹${Number(Number(order.price) * Number(order.qty)).toFixed(2)}</td>

          </tr>`).join('')}
            </tbody>
           <tfoot>

            <tr align="center">
            <td colspan="3"  class="content right" width="10%"></td>
            <td class="content right" width="10%"></td>
          </tr>


          
            <tr align="center">
            <td colspan="3"  class="content right" width="10%"></td>
            <td class="content right" width="10%"></td>
          </tr>
  
         <tr align="center">
            <td colspan="3"  class="content right" width="10%">Sub Total</td>
            <td class="content right" width="10%">₹ ${Number(gTotal).toFixed(2)}</td>
          </tr>


           ${discountRow}

            ${deliveryRow}


           <tr align="center">
            <td colspan="3"  class="content right" width="10%">CGST (2.5%):</td>
            <td class="content right" width="10%">₹ ${Number(cgst).toFixed(2)}</td>
          </tr>

           <tr align="center">
            <td colspan="3"  class="content right" width="10%">CGST (2.5%):</td>
            <td class="content right" width="10%">₹ ${Number(sgst).toFixed(2)}</td>
          </tr>

           <tr align="center">
            <td colspan="3"  class="content right" width="10%">Grand Total:</td>
            <td class="content right" width="10%"><b>₹ ${Number(gTotal).toFixed(2)}</b></td>
          </tr><br>



         
        
        </tfoot>
         
      </table><br>
     
     
   

      <div  class="address"></div>
     <hr></hr>
      
      <div class="address">FSSAI LIC NO:12420003001528</div>

      <br></br>

<!-- Page Break -->
<div class="page-break"></div>
      
   
      


      <!-- Resto Control Info and Pricing -->
       <div style="display: flex; justify-content: center; align-items: center;">
       <img  src="${logo}" width="193" height="60" />
  </div><br>
    
       <div class="address">+91 82202 45554</div>
      <div class="address">GSTIN :33DAGPP5227K2ZH</div><br>

 
      <hr></hr>
      <div class="address1">${area + ', ' + section}</div>
       <div class="address1">Date:${formattedDateTime}</div>
       <div class="address1">Bill No : #${orderId}</div>
  
<hr></hr>
      <table align="center" width="100%">
          <thead>
    <tr class="borders">
      <th class="content left" width="70%">Item</th>
       <th class="content middle" width="10%">Price</th>
      <th class="content middle" width="10%">Qty</th>
      <th class="content middle" width="10%">Amount</th>
    </tr>
  </thead>
            <tbody>
        ${orders.map(order => `
          <tr align="center">
         
            <td class="content left" width="70%">${order.item}</td>
            <td class="content middle" width="10%">${Number(order.price).toFixed(2)}</td>
            <td class="content middle" width="10%">${order.qty}</td>
            <td class="content right" width="20%">₹${Number(Number(order.price) * Number(order.qty)).toFixed(2)}</td>

          </tr>`).join('')}
            </tbody>
           <tfoot>

            <tr align="center">
            <td colspan="3"  class="content right" width="10%"></td>
            <td class="content right" width="10%"></td>
          </tr>


          
            <tr align="center">
            <td colspan="3"  class="content right" width="10%"></td>
            <td class="content right" width="10%"></td>
          </tr>
  
         <tr align="center">
            <td colspan="3"  class="content right" width="10%">Sub Total</td>
            <td class="content right" width="10%">₹ ${Number(gTotal).toFixed(2)}</td>
          </tr>


           ${discountRow}

            ${deliveryRow}


           <tr align="center">
            <td colspan="3"  class="content right" width="10%">CGST (2.5%):</td>
            <td class="content right" width="10%">₹ ${Number(cgst).toFixed(2)}</td>
          </tr>

           <tr align="center">
            <td colspan="3"  class="content right" width="10%">CGST (2.5%):</td>
            <td class="content right" width="10%">₹ ${Number(sgst).toFixed(2)}</td>
          </tr>

           <tr align="center">
            <td colspan="3"  class="content right" width="10%">Grand Total:</td>
            <td class="content right" width="10%"><b>₹ ${Number(gTotal).toFixed(2)}</b></td>
          </tr><br>



         
        
        </tfoot>
         
      </table><br>
     
     
   

      <div  class="address"></div>
     <hr></hr>
      
      <div class="address">FSSAI LIC NO:12420003001528</div>
     
    </body>
  </html>
    `;
    
    }else{
      return `
      <html>
      <head>
        <title>Order Details</title>
        <link rel="stylesheet" href="styles.css">
         <style>
             
              body {
                margin: 0px; 
              }
              .header {
                font-size: 18px;
                font-weight: bold;
                text-align: center;
                margin-bottom: 5px; 
              }
              .address {
                font-size: 12px;
                font-weight: bold;
                text-align: center;
                margin-bottom: 0px; 
              }
              .content {
                font-size: 14px;
                font-weight: bold;
                margin-bottom: 10px;
              }
              .left {
                text-align:left;
              }
              .right{
                text-align:right;
              }
            </style>
      </head>
      <body>
        <div class="header">KOT-${userName}</div>
        <div class="address">${formattedDateTime}</div><br>
        <table align="center">
          ${orders.map(
            (order) => `
              <tr align="center">
                <td class="content left" width="85%" style="font-size: 16px">${order.item}</td>
                <td class="content right" style="font-size: 16px">${order.qty}</td>
              </tr>`
          ).join('')}
        </table><br>
        <div class="address">${area + ', ' + section}</div>
        <div class="address">${chair + ', '}</div>
      </body>
      </html>
    `;
    }
    

   
    
   
    
  };

  
  
  
  
  

  const generateShortcuts = (productName) => {
    const words = productName;
    let shortcuts = "";
   
    return shortcuts.toUpperCase();
  };

  return (
    
    <div class="g-sidenav-show   bg-gray-100">
    <div class="min-height-300 bg-primary position-absolute w-100"></div>
    { userType === '1' ?(
    <SideBar />
      ) : (< EsideBar />
     )}

    <main class="main-content position-relative border-radius-lg ">
    
    <NavBar />
    <div class="card shadow-lg mx-2 mt-5 card-profile-bottom">
      <div class="card-body p-2">
        <div class="row gx-4">
          <div class="col-12">
               <div class="nav-wrapper position-relative end-0">
              <ul class="nav nav-pills nav-fill p-1 " role="tablist">
			  
                <li class="nav-item p-2">
                  <a class="nav-link mb-0 px- py-2 active  d-flex align-items-center justify-content-center " href="javascript;" onClick={() => handleLinkClick('TakeAway')} data-bs-toggle="tab" data-bs-target="#takeAway" role="tab" aria-selected="true" rel="noreferrer">
                    <i class="ni ni-basket"></i>
                    <span class="ms-2">Take Away</span>
                  </a>
                </li>
                <li class="nav-item p-2">
                  <a class="nav-link mb-0 px- py-2 d-flex align-items-center justify-content-center " href="javascript;" onClick={() => handleLinkClick('Delivery')} data-bs-toggle="tab" data-bs-target="#delivery" role="tab" aria-selected="true" rel="noreferrer">
                    <i class="ni ni-delivery-fast"></i>
                    <span class="ms-2">Delivery</span>
                  </a>
                </li>
                 <li class="nav-item p-2">
                  <a class="nav-link mb-0 px- py-2 d-flex align-items-center justify-content-center " href="javascript;" onClick={() => handleLinkClick('Floor')} data-bs-toggle="tab" data-bs-target="#floor" role="tab" aria-selected="true" rel="noreferrer">
                    <i class="ni ni-shop"></i>
                    <span class="ms-2">Floor Orders</span>
                  </a>
                </li>
              {/*  <li class="nav-item p-2">
                  <a class="nav-link mb-0 px-0 py-2 d-flex align-items-center justify-content-center " href="javascript;" onClick={() => handleLinkClick('AC_Hall')} data-bs-toggle="tab" data-bs-target="#ACHALL" role="tab" aria-selected="false" rel="noreferrer">
                    <i class="ni ni-tie-bow"></i>
                    <span class="ms-2">AC HALL</span>
                  </a>
                </li> */}
                <li class="nav-item p-2">
                  <a class="nav-link mb-0 px-0 py-2 d-flex align-items-center justify-content-center " href="javascript;" onClick={() => handleLinkClick('Outer_Table')} data-bs-toggle="tab" data-bs-target="#outertable" role="tab" aria-selected="false" rel="noreferrer">
                    <i class="ni ni-image"></i>
                    <span class="ms-2">OUTER TABLES</span>
                  </a>
                </li>
				        {/* <li class="nav-item p-2">
                  <a class="nav-link mb-0 px-0 py-2 d-flex align-items-center justify-content-center " href="javascript;" onClick={() => handleLinkClick('Roof_Top')} data-bs-toggle="tab" data-bs-target="#rooftop" role="tab" aria-selected="false" rel="noreferrer">
                    <i class="ni ni-box-2"></i>
                    <span class="ms-2">ROOF TOP TABLES</span>
                  </a>
                </li> */}
              </ul>
			  <div class="tab-content">
					
					 
        <div class="tab-pane fade show active" id="takeAway" role="tabpanel">
					  <div class="card-body">
              <p class="text-uppercase text-sm">Add ITEMS</p>
           
              <div class="row">
              <div class="col-md-6">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Customer Name</label>
                    <input type="text"  class="form-control"  value={cName}  placeholder="Enter Customer Name..." onChange={(e) => setcName(e.target.value)} />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Customer Phone Number</label>
                    <input type="number"  class="form-control" placeholder="Enter Customer Phone..." value={cPhone} onChange={(e) => {
        const value = e.target.value;

        // Allow only numbers and restrict length to 10
        if (/^\d{0,10}$/.test(value)) {
          setcPhone(value);
          // Check if the length of the value is exactly 10, not cPhone
          if (value.length === 10) {
            handlechangephonenumber(value);
          }
        }
      }}
      maxLength={10}
    />
                  </div>
                </div>


              

 


                
                <div className="col-md-6">
  <div className="form-group">
    <label htmlFor="discount1" className="form-control-label">
      &nbsp;
      <input
        type="checkbox"
        id="discount1"
        className="custom-checkbox"
        checked={discount1Checked}
        onChange={handleCheckboxChange1}
        style={{ marginRight: '10px' }}
      />
      Discount
    </label>
    <div className="col-md-12">
    {/* Show the second checkbox if the first is checked */}
    {discount1Checked && (
      <>
      <label htmlFor="discount3" className="form-control-label" >
        <input
          type="radio"
          id="discount3"
          className="custom-radio"
          name="discountType"
          checked={discount3Checked}
          onChange={handleRadioChange3}
          style={{ marginRight: '10px' }}
        />
        Discount in %
      </label>

      <label htmlFor="discount4" className="form-control-label" style={{ paddingLeft: '10px' }}>
        <input
          type="radio"
          id="discount4"
          className="custom-radio"
          name="discountType"
          checked={discount4Checked}
          onChange={handleRadioChange4}
          style={{ marginRight: '10px' }}
        />
        Discount in Flat
      </label>
    </>
    )}
    </div>

    {/* Show the input field only if discount3Checked is true */}
    {(discount3Checked || discount4Checked) && (
  <input
    type="text"
    className="form-control"
    placeholder="Enter Discount..."
    value={discount}
    onChange={handledisount}
  />
)}

  </div>
</div>


      <div className="col-md-6">
        <div className="form-group">
          <label htmlFor="discount2" className="form-control-label">
          <input 
            type="checkbox" 
            id="discount2" 
            className="custom-checkbox" 
            checked={discount2Checked} 
            onChange={handleCheckboxChange2} 
            style={{ marginRight: '10px' }}
          />
          Delivery Charge</label>
          
          {/* Show the input field only if checkbox is checked */}
          {discount2Checked && (
            <input 
              type="number"  
              className="form-control" 
              placeholder="Enter Delivery Charge..." 
              value={cdelivery}
               onChange={(e) => setdelivery(e.target.value)}
            />
          )}
        </div>
      </div>


      <div class="col-md-6">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Description</label>
                   <input  class="form-control"  placeholder="Enter Description..." value={desc} onChange={(e) => setdesc(e.target.value)} />
                  </div>
                </div>


      
   


                <table className="col-md-12 mx-4 text-center text-sm" style={{ background: "#F9F9F9" }}>
                       <tbody>
                         {orders.map((order) => (
                           <tr key={order.id}> {/* Ensure each row has a unique key */}
                             <td width="70%">{order.item}</td>
                             <td width="8%">{order.qty}</td>
                             <td width="12%">{order.qty * order.price}</td>
                             <td width="10%">
                               <button
                                 className="btn btn-danger btn-m ms-0 my-2 me-2"
                                 onClick={() => handleDelete(order.id)}
                               >
                                 <i className="ni ni-basket"></i>
                               </button>
                             </td>
                           </tr>
                         ))}
                       </tbody>
                       <tfoot>
                         {grandTotal > 0 ?  (<> <tr>
                            <td></td>
                            <td colSpan="1">Total</td>
                           <td colSpan="1"> {grandTotal} </td>
                         </tr></>):(<></>)}
                        
                       </tfoot>
                  </table>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">ITEM</label>
                    <input type="text" list="products" class="form-control" name="item" placeholder="Enter Item.." value={item} onChange={itemChange} required />
                    <datalist id="products">
                    {product?.data?.map(product => (
                        <option key={product.product_id} value={product.cat}>
                               {product.shortCode}
                        </option>
                    ))}
                    </datalist>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Quantity</label>
                    <input type="number" class="form-control" name="qty" min="1" placeholder="Enter Quantity..." value={qty}  onChange={qtyChange} required />      
                  </div>
                </div>
               
    <div className="col-md-2">
      <div className="form-group">
        <label htmlFor="example-text-input" className="form-control-label">Price</label>
        <input
          type="number"
          className="form-control"
          name="price"
          placeholder="Enter Price..."
          value={qty * price}
          onChange={(e) => setPrice(e.target.value)}
          required
        />
      </div>
    </div>
    <div className="col-md-2 my-4 py-2">
      <button class="btn btn-primary btn-sm ms-auto" onClick={() => additem()}>ADD</button>
    </div>
 

              </div>
           
              <div class="col-md-3">
                   <button class="btn btn-success btn-m " onClick={() => handlestock()}>Confirm Order</button>
              </div>
             
              <div  class="col-md-9">
                   <p style={{ color: 'red' }}>{formError.name}</p>
              </div>
              

                
            </div>
					   </div>

             <div class="tab-pane fade" id="delivery" role="tabpanel">
					  <div class="card-body">
              <p class="text-uppercase text-sm">Add ITEMS</p>
             
              <div class="row">
              <div class="col-md-6">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Customer Name</label>
                    <input type="text"  class="form-control"  value={cName}  placeholder="Enter Customer Name..." onChange={(e) => setcName(e.target.value)} />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Customer Phone Number</label>
                    <input type="number"  class="form-control" placeholder="Enter Customer Phone..." value={cPhone} onChange={(e) => {
        const value = e.target.value;

        // Allow only numbers and restrict length to 10
        if (/^\d{0,10}$/.test(value)) {
          setcPhone(value);
          // Check if the length of the value is exactly 10, not cPhone
          if (value.length === 10) {
            handlechangephonenumber(value);
          }
        }
      }}
      maxLength={10}
    />
                  </div>
                </div>
                  <div class="col-md-12">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Address</label>
                   <input  class="form-control"  placeholder="Enter Customer Address..." value={cAddress} onChange={(e) => setcAddress(e.target.value)} />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Delivery Charge</label>
                   <input type="number" class="form-control"  placeholder="Enter Delivery Charge..." value={cdelivery} onChange={(e) => setdelivery(e.target.value)} />
                  </div>
                </div>


                <div class="col-md-6">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Description</label>
                   <input  class="form-control"  placeholder="Enter Description..." value={desc} onChange={(e) => setdesc(e.target.value)} />
                  </div>
                </div>

                
                <table className="col-md-12 mx-4 text-center text-sm" style={{ background: "#F9F9F9" }}>
                       <tbody>
                         {orders.map((order) => (
                           <tr key={order.id}> {/* Ensure each row has a unique key */}
                             <td width="70%">{order.item}</td>
                             <td width="8%">{order.qty}</td>
                             <td width="12%">{order.qty * order.price}</td>
                             <td width="10%">
                               <button
                                 className="btn btn-danger btn-m ms-0 my-2 me-2"
                                 onClick={() => handleDelete(order.id)}
                               >
                                 <i className="ni ni-basket"></i>
                               </button>
                             </td>
                           </tr>
                         ))}
                       </tbody>
                       <tfoot>
                         {grandTotal > 0 ?  (<> <tr>
                            <td></td>
                            <td colSpan="1">Total</td>
                           <td colSpan="1"> {grandTotal} </td>
                         </tr></>):(<></>)}
                        
                       </tfoot>
                  </table>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">ITEM</label>
                    <input type="text" list="products" class="form-control" name="item" placeholder="Enter Item.." value={item} onChange={itemChange} required />
                    <datalist id="products">
                    {product?.data?.map(product => (
                        <option key={product.product_id} value={product.cat}>
                               {product.shortCode}
                        </option>
                    ))}
                    </datalist>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Quantity</label>
                    <input type="number" class="form-control" name="qty" min="1" placeholder="Enter Quantity..." value={qty}  onChange={qtyChange} required />      
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Price</label>
                    <input type="number" class="form-control" name="price" placeholder="Enter Price..."  value={qty * price} onChange={(e) => setPrice(e.target.value)} required />       
                  </div>
                </div>
                <div class="col-md-2 my-4 py-2">
                   <button class="btn btn-primary btn-sm ms-auto" onClick={() => additem()}>ADD</button>
                </div>
              </div>
            
              <div class="col-md-3">
                   <button class="btn btn-success btn-m ms-auto" onClick={() => handlestock()}>Confirm Order</button>
              </div>
              <div  class="col-md-9">
                   <p style={{ color: 'red' }}>{formError.name}</p>
              </div>
                
            </div>
					   </div>


             <div class="tab-pane fade" id="floor" role="tabpanel">
					  <div class="card-body">
              <p class="text-uppercase text-sm">Add ITEMS</p>
           
              <div class="row">
              <table className="col-md-12 mx-4 text-center text-sm" style={{ background: "#F9F9F9" }}>
                       <tbody>
                         {orders.map((order) => (
                           <tr key={order.id}> {/* Ensure each row has a unique key */}
                             <td width="70%">{order.item}</td>
                             <td width="8%">{order.qty}</td>
                             <td width="12%">{order.qty * order.price}</td>
                             <td width="10%">
                               <button
                                 className="btn btn-danger btn-m ms-0 my-2 me-2"
                                 onClick={() => handleDelete(order.id)}
                               >
                                 <i className="ni ni-basket"></i>
                               </button>
                             </td>
                           </tr>
                         ))}
                       </tbody>
                       <tfoot>
                         {grandTotal > 0 ?  (<> <tr>
                            <td></td>
                            <td colSpan="1">Total</td>
                           <td colSpan="1"> {grandTotal} </td>
                         </tr></>):(<></>)}
                        
                       </tfoot>
                  </table>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">ITEM</label>
                    <input type="text" list="products" class="form-control" name="item" placeholder="Enter Item.." value={item} onChange={itemChange} required />
                    <datalist id="products">
                    {product?.data?.map(product => (
                        <option key={product.product_id} value={product.cat}>
                               {product.shortCode}
                        </option>
                    ))}
                    </datalist>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Quantity</label>
                    <input type="number" class="form-control" name="qty" min="1" placeholder="Enter Quantity..." value={qty}  onChange={qtyChange} required />      
                  </div>
                </div>
               
               <div className="col-md-2">
                 <div className="form-group">
                   <label htmlFor="example-text-input" className="form-control-label">Price</label>
                   <input
                     type="number"
                     className="form-control"
                     name="price"
                     placeholder="Enter Price..."
                     value={qty * price}
                     onChange={(e) => setPrice(e.target.value)}
                     required
                   />
                 </div>
               </div>
               <div className="col-md-2 my-4 py-2">
                 <button class="btn btn-primary btn-sm ms-auto" onClick={() => additem()}>ADD</button>
               </div>
                  
           
           
               <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="discount1" className="form-control-label">
                      &nbsp;
                      <input
                        type="checkbox"
                        id="discount1"
                        className="custom-checkbox"
                        checked={discount1Checked}
                        onChange={handleCheckboxChange1}
                        style={{ marginRight: '10px' }}
                      />
                      Discount
                    </label>
                    <div className="col-md-12">
                    {/* Show the second checkbox if the first is checked */}
                    {discount1Checked && (
                      <>
                      <label htmlFor="discount3" className="form-control-label" >
                        <input
                          type="radio"
                          id="discount3"
                          className="custom-radio"
                          name="discountType"
                          checked={discount3Checked}
                          onChange={handleRadioChange3}
                          style={{ marginRight: '10px' }}
                        />
                        Discount in %
                      </label>
                
                      <label htmlFor="discount4" className="form-control-label" style={{ paddingLeft: '10px' }}>
                        <input
                          type="radio"
                          id="discount4"
                          className="custom-radio"
                          name="discountType"
                          checked={discount4Checked}
                          onChange={handleRadioChange4}
                          style={{ marginRight: '10px' }}
                        />
                        Discount in Flat
                      </label>
                    </>
                    )}
                    </div>
                
                    {/* Show the input field only if discount3Checked is true */}
                    {(discount3Checked || discount4Checked) && (
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Discount..."
                    value={discount}
                    onChange={handledisount}
                  />
                )}
                
                  </div>
                </div>

 

              </div>
           
              <div class="col-md-3">
                   <button class="btn btn-success btn-m " onClick={() => handlestock()}>Confirm Order</button>
              </div>
             
              <div  class="col-md-9">
                   <p style={{ color: 'red' }}>{formError.name}</p>
              </div>
              

                
            </div>
					   </div>
					   
					   
					 {/* <div class="tab-pane fade" id="ACHALL" role="tabpanel">
					  <div class="card-body">
              <p class="text-uppercase text-sm">Add ITEMS</p>
              <form onSubmit={handleSubmit}>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">SELECT TABLE</label>
                    <select class="form-control" name="section" value={section} onChange={(e) => setHut(e.target.value)} required >
						      <option value="" disabled>Select a Table</option>
                  {acHallData && acHallData.data && acHallData.data
                      .filter(acHall => acHall.table_status == 'yes')
                      .map(acHall=> (
                        <option key={acHall.table_id}>{acHall.table_name}</option>
                    ))}
					        </select>
                  </div>
                </div>

                <table class="col-md-12 mx-0 text-center text-sm" style={{background:"#f9f9f9",border:"1px solid #23fe45"}}> 
                 {orders.map((order, index) => (
                  
                   <tr key={index} >

                        <td width="70%">{order.item}</td>
                        <td width="8%">{order.qty} </td>   
                        <td width="12%">{order.qty * order.price} </td>  
                        <td width="10%">
                        <button class="btn btn-danger btn-m ms-0 my-2 me-2"  onClick={() => handleDelete(order.id)}><i class="ni ni-basket"></i></button>
                        </td>
                   </tr>
                  ))}
                </table>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">ITEM</label>
                    <input type="text" list="products" class="form-control" name="item" placeholder="Enter Item.." value={item} onChange={itemChange} required />
                    <datalist id="products">
                    {product?.data?.map(product => (
                             <option key={product.product_id} value={product.cat}>
                                {generateShortcuts(product.product_cat)} - {product.cat}
                             </option>
                    ))}
                    </datalist>           
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Quantity</label>
                    <input type="number" class="form-control" name="qty" min="1" placeholder="Enter Quantity..." value={qty}  onChange={qtyChange} required />
                                
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Price</label>
                    <input type="number" class="form-control" name="price" placeholder="Enter Price..." value={price} onChange={(e) => setPrice(e.target.value)} required />
                                
                  </div>
                </div>
                <div class="col-md-2 my-4 py-2">
                   <button class="btn btn-primary btn-sm ms-auto">ADD</button>
                </div>

              </div>
              </form>
              <div class="col-md-3">
                   <button class="btn btn-success btn-m ms-auto" onClick={() => handleOrder()}>Confirm Order</button>
              </div>
              <div  class="col-md-9">
                   <p style={{ color: 'red' }}>{formError.name}</p>
              </div>
            </div>
					   </div>   */}
					   
					   
					   
					   
					   <div class="tab-pane fade" id="outertable" role="tabpanel">
					  <div class="card-body">
              <p class="text-uppercase text-sm">Add ITEMS</p>
             
              <div class="row">
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">SELECT TABLE</label>
                    <select class="form-control" name="section" value={section} onChange={(e) => setHut(e.target.value)} required >
						      <option value="" disalbed>Select a Table</option>
						      
                  {outerData && outerData.data && outerData.data
                      .filter(outer => outer.table_status == 'yes')
                      .map(outer => (
                             <option key={outer.table_id}>{outer.table_name}</option>
                    ))}

						  </select>
                  </div>
                </div>
                <div className="col-md-9">
  <div className="form-group">
    <label className="form-control-label">Select Chairs</label>
    <div style={{ display: 'flex', gap: '20px', width: "100%", flexWrap:"wrap"}}>
     
      <div>
        <button
          type="button"
          id="chair-1"
          className={`btn btn-sm ${chair.includes('chair-1') ? 'btn-primary' : 'btn-outline-primary'}`}
          onClick={() => handleChairToggle('chair-1')}
        >
          1
        </button>
      </div>
      <div>
        <button
          type="button"
          id="chair-2"
          className={`btn btn-sm ${chair.includes('chair-2') ? 'btn-primary' : 'btn-outline-primary'}`}
          onClick={() => handleChairToggle('chair-2')}
        >
          2
        </button>
      </div>
      <div>
        <button
          type="button"
          id="chair-3"
          className={`btn btn-sm ${chair.includes('chair-3') ? 'btn-primary' : 'btn-outline-primary'}`}
          onClick={() => handleChairToggle('chair-3')}
        >
         3
        </button>
      </div>


      
      <div>
        <button
          type="button"
          id="chair-4"
          className={`btn btn-sm ${chair.includes('chair-4') ? 'btn-primary' : 'btn-outline-primary'}`}
          onClick={() => handleChairToggle('chair-4')}
        >
          4
        </button>
      </div>


      {section=== 'Table-4' ?(<> <div>
        <button
          type="button"
          id="chair-4"
          className={`btn btn-sm ${chair.includes('chair-5') ? 'btn-primary' : 'btn-outline-primary'}`}
          onClick={() => handleChairToggle('chair-5')}
        >
          5
        </button>
      </div>


      <div>
        <button
          type="button"
          id="chair-4"
          className={`btn btn-sm ${chair.includes('chair-6') ? 'btn-primary' : 'btn-outline-primary'}`}
          onClick={() => handleChairToggle('chair-6')}
        >
          6
        </button>
      </div></>):(<></>)}
     
   
    <div>
    {section === 'Table-4' ? (
     <button
     type="button"
     className="btn btn-info"
     onClick={handleSelectAllToggle}
   >
     {chair.length === 6 ? 'Deselect All' : 'Select All'}
    
   </button>
  ) : (
    <button
        type="button"
        className="btn btn-info"
        onClick={handleSelectAllToggle}
      >
        {chair.length === 4 ? 'Deselect All' : 'Select All'}
       
      </button>
  )}
   
      </div>
    </div>

  </div>
</div>




                <table className="col-md-12 mx-4 text-center text-sm" style={{ background: "#F9F9F9" }}>
                       <tbody>
                         {orders.map((order) => (
                           <tr key={order.id}> {/* Ensure each row has a unique key */}
                             <td width="70%">{order.item}</td>
                             <td width="8%">{order.qty}</td>
                             <td width="12%">{order.qty * order.price}</td>
                             <td width="10%">
                               <button
                                 className="btn btn-danger btn-m ms-0 my-2 me-2"
                                 onClick={() => handleDelete(order.id)}
                               >
                                 <i className="ni ni-basket"></i>
                               </button>
                             </td>
                           </tr>
                         ))}
                       </tbody>
                       <tfoot>
                         {grandTotal > 0 ?  (<> <tr>
                            <td></td>
                            <td colSpan="1">Total</td>
                           <td colSpan="1"> {grandTotal} </td>
                         </tr></>):(<></>)}
                        
                       </tfoot>
                  </table>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">ITEM</label>
                    <input type="text" list="products" class="form-control" name="item" placeholder="Enter Item.." value={item} onChange={itemChange} required />
                    <datalist id="products">
                    {product?.data?.map(product => (
                            <option key={product.p_id} value={product.cat}>
                                 {product.shortCode}
                            </option>
                    ))}
                    </datalist>           
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Quantity</label>
                    <input type="number" class="form-control" name="qty" min="1" placeholder="Enter Quantity..." value={qty}  onChange={qtyChange} required />
                                
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Price</label>
                    <input type="number" class="form-control" name="price" placeholder="Enter Price..."  value={qty * price} onChange={(e) => setPrice(e.target.value)} required />
                                
                  </div>
                </div>
                <div class="col-md-2 my-4 py-2">
                <button class="btn btn-primary btn-sm ms-auto" onClick={() => additem()}>ADD</button>
                </div>

              </div>
             

              <div class="col-md-3">
                   <button class="btn btn-success btn-m ms-auto" onClick={() => handleOrder()}>Confirm Order</button>
              </div>
              <div  class="col-md-9">
                   <p style={{ color: 'red' }}>{formError.name}</p>
              </div>
            </div>
					   </div>  
					   
					   
					   
					   
					   {/* <div class="tab-pane fade" id="rooftop" role="tabpanel">
					  <div class="card-body">
              <p class="text-uppercase text-sm">Add ITEMS</p>
          
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">SELECT TABLE</label>
                    <select class="form-control" name="section" value={section} onChange={(e) => setHut(e.target.value)} required >
						      <option value="" disabled>Select a Table</option>
						      {rfTableData && rfTableData.data && rfTableData.data
                    .filter(rfTable => rfTable.table_status == 'yes')
                    .map(rfTable => (
                             <option key={rfTable.table_id}>{rfTable.table_name}</option>
                    ))}
					</select>
                  </div>
                </div>

                <table class="col-md-12 mx-0 text-center text-sm" style={{background:"#f9f9f9",border:"1px solid #23fe45"}}> 
                 {orders.map((order, index) => (
                  
                   <tr key={index} >

                        <td width="70%">{order.item}</td>
                        <td width="8%">{order.qty} </td>   
                        <td width="12%">{order.qty * order.price} </td>  
                        <td width="10%">
                        <button class="btn btn-danger btn-m ms-0 my-2 me-2"  onClick={() => handleDelete(order.id)}><i class="ni ni-basket"></i></button>
                        </td>
                   </tr>
                  ))}
                </table>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">ITEM</label>
                    <input type="text" list="products" class="form-control" name="item" placeholder="Enter Item.." value={item} onChange={itemChange} required />
                    <datalist id="products">
                    {product?.data?.map(product => (
                        <option key={product.product_id} value={product.cat}>
                              {generateShortcuts(product.product_cat)} - {product.cat}
                        </option>
                    ))}
                    </datalist>           
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Quantity</label>
                    <input type="number" class="form-control" name="qty" min="1" placeholder="Enter Quantity..." value={qty}  onChange={qtyChange} required />
                                
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Price</label>
                    <input type="number" class="form-control" name="price" placeholder="Enter Price..." value={price} onChange={(e) => setPrice(e.target.value)} required />
                                
                  </div>
                </div>
                <div class="col-md-2 my-4 py-2">
                <button class="btn btn-primary btn-sm ms-auto" onClick={() => additem()}>ADD</button>
                </div>
                
              </div>
             
              <div class="col-md-3">
                   <button class="btn btn-success btn-m ms-auto" onClick={() => handleOrder()}>Confirm Order</button>
              </div>
              <div  class="col-md-9">
                   <p style={{ color: 'red' }}>{formError.name}</p>
              </div>
            </div>
					   </div>   */}
					   
					   
					   
          </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
    <div class="container-fluid py-4">

      <Footer />
    </div>
  </main>

  </div>
  );
}

export default App;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ReactSession }  from 'react-client-session';
import { useNavigate } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import * as XLSX from 'xlsx';
import SideBar from './sidebar';
import NavBar from './navbar';
import Footer from './footer';
import { Paginator } from 'primereact/paginator';

 function App() {

  const userId = ReactSession.get("userId");
  const userType = ReactSession.get("userType");
  const navigate = useNavigate();
  const indexPath = '/';
  const orderPath = '/order';
  const [first, setFirst] = useState(0); 
  const [rows, setRows] = useState(10); 
  
  useEffect(() => {
    if (userId == 0 ) {
      navigate(indexPath);
    }
    if (userType == 2 ) {
      navigate(orderPath);
    }
  }, [userId, navigate]);

  const [contacts, setData] = useState(true);
   const [searchQuery, setSearchQuery] = useState('');
    const [filteredData, setFilteredData] = useState(contacts && contacts.data && contacts.data);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://projects.sninfoserv.com/fbh-api/contacts/');
        setData(response.data);
        console.log(response.data);
      } catch (error) {
      } 
    };

    fetchData();
  }, []);

  const handleSearchInputChange = (event) => {
	  
	
    const query = event.target.value;
    setSearchQuery(query);
  
    // Filter datas.data based on search query
    const filtered = contacts && contacts.data && contacts.data.filter(contact =>
      
      contact.customer_phone.toLowerCase().includes(query.toLowerCase()) 
    );
    setFilteredData(filtered);
  
  
  };


  
  

  const downloadExcel = () => {
    const fileName = "resto-control_contacts.xlsx";

    const columns = ["Name", "WhatsApp", "Contact", "Address"]; 

    const data = [columns];
    if (contacts && Array.isArray(contacts.data)) {
       
        contacts.data.forEach(contact => {
            data.push([
                contact.customer_name || '', 
                contact.customer_whatsapp || '', 
                contact.customer_phone || '',
                contact.customer_address || '' 
            ]);
        });
    }

    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Contacts");

    XLSX.writeFile(wb, fileName);
}

const onPageChange = (event) => {
  setFirst(event.first);
  setRows(10); // Update rows to display only one row per page
};



  return (
    <div class="g-sidenav-show   bg-gray-100">
    <div class="min-height-300 bg-primary position-absolute w-100"></div>
    <SideBar />

    <main class="main-content position-relative border-radius-lg ">
    
    <NavBar />
   
    <div class="container-fluid py-4">
    <div class="row">
        <div class="col-12">
          <div class="card mb-4">
            <div class="card-header pb-0">
              <h6>Contacts
                <button class="btn btn-warning btn-sm ms-auto" style={{float:'right',}} onClick={downloadExcel}> <i class="fa fa-download"></i></button>
              </h6>

              <div className="col-lg-3 px-2">
            <input
              type="text"
              className="form-control"
              placeholder="Search by Phone Number.."
             value={searchQuery}
              onChange={handleSearchInputChange}
            />
          </div>
                   </div>
            <div class="card-body px-0 pt-0 pb-2">
              <div class="table-responsive p-0">
                <table class="table align-items-center justify-content-center mb-0">
                  <thead>
                 
                    <tr>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Name</th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">WhatsApp</th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder  opacity-7">Contact</th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Address</th>
                      <th>Action</th>
                    </tr>
                  
                  </thead>
                  <tbody>
                  {searchQuery && filteredData.length > 0 ? (
            <>  {filteredData.map(contact => ( 
              <tr>
                       <td>
                        <span class="text-xs font-weight-bold">{contact.customer_name}</span>
                      </td>
                      <td>
                        <span class="text-xs font-weight-bold">{contact.customer_whatsapp}</span>
                      </td>
                      <td>
                        <span class="text-xs font-weight-bold">{contact.customer_phone}</span>
                      </td>
                     
                      <td>
  <span
    className="text-xs font-weight-bold"
    style={{
      whiteSpace: 'normal',
      wordWrap: 'break-word',
      wordBreak: 'break-word'
    }}
  >
    {contact.customer_address}
  </span>
</td>

                      <td class="align-middle text-center">
                          <a href={`editcontact?id=${contact.customer_id}`} class="mx-2">
                          <button class="btn btn-success btn-sm ms-auto">
                               Edit
                            </button>
                          </a>
                         
                      </td>
                     
                      
                    </tr>
            ))}
            </>
            
            ) : searchQuery && filteredData.length === 0 ? (
              <tr >
                           <td>No products found </td>
                         </tr>
           ) : (<>
                  {contacts && contacts.data && contacts.data.slice().reverse().slice(first, first + rows).map(contact => (
                    <tr>
                       <td>
                        <span class="text-xs font-weight-bold">{contact.customer_name}</span>
                      </td>
                      <td>
                        <span class="text-xs font-weight-bold">{contact.customer_whatsapp}</span>
                      </td>
                      <td>
                        <span class="text-xs font-weight-bold">{contact.customer_phone}</span>
                      </td>
                     
                      <td>
  <span
    className="text-xs font-weight-bold"
    style={{
      whiteSpace: 'normal',
      wordWrap: 'break-word',
      wordBreak: 'break-word'
    }}
  >
    {contact.customer_address}
  </span>
</td>

                      <td class="align-middle text-center">
                          <a href={`editcontact?id=${contact.customer_id}`} class="mx-2">
                          <button class="btn btn-success btn-sm ms-auto">
                               Edit
                            </button>
                          </a>
                         
                      </td>
                     
                      
                    </tr>
                  ))}
                  </>)}
                    
                  </tbody>
                </table>

                <Paginator className="custom-paginator" first={first} rows={rows} totalRecords={contacts && contacts.data && contacts.data ? contacts && contacts.data && contacts.data.length : 0}  onPageChange={onPageChange} />
                             
              </div>
            </div>
          </div>
        </div>
      </div>
   
      <Footer />
    </div>
  </main>

  </div>
  )
  
}
export default App;
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/index';
import DashBoard from './pages/dashboard';
import NewOrder from './pages/neworder';
import WhatsApp from './pages/whatsapp';
import DineIn from './pages/dinein';
import Details from './pages/orderdetails';
import Employee from './pages/employee';
import Eprofile from './pages/editprofile';
import Manage from './pages/tablemanage';
import Contacts from './pages/contacts';
import AddEmployee from './pages/add_employees';
import Salary from './pages/salary';
import Website from './pages/website';
import WebsiteOrders from './pages/webOrderDetails';
import Test from './pages/test';
import Send from './pages/send';
import Product from './pages/product';
import AddProduct from './pages/add_product';
import EditProduct from './pages/edit_product';
import Addcategory from './pages/add_category';
import Category from './pages/category';
import AddSubcategory from './pages/add_subcategory.js';
import DailyReports from './pages/dailyreport';
import EditBill from './pages/editbill.js';
import Editcontact from './pages/editcontact.js';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/:id" element={<HomePage />} />
        <Route path="/" element={<HomePage />} />
        <Route path="/dashboard" element={<DashBoard/>} />
        <Route path="/order" element={<NewOrder/>} />
        <Route path="/whatsapp" element={<WhatsApp/>} />
        <Route path="/dinein" element={<DineIn/>} />
        <Route path="/orderdetails/" element={<Details/>} />
        <Route path="/employee/" element={<Employee/>} />
        <Route path="/edit-profile/" element={<Eprofile/>} />
        <Route path="/manage/" element={<Manage/>} />
        <Route path="/contacts/" element={<Contacts/>} />
        <Route path="/add-employee/" element={<AddEmployee/>} />
        <Route path="/salary/" element={<Salary/>} />
        <Route path="/website/" element={<Website/>} />
        <Route path="/order-details/" element={<WebsiteOrders/>} />
        <Route path="/test" element={<Test/>} />
        <Route path="/send" element={<Send/>} />
        <Route path="/product" element={<Product/>} />
        <Route path="/add_product" element={<AddProduct/>} />
        <Route path="/edit_product" element={<EditProduct/>} />
        <Route path="/add_category" element={<Addcategory/>} />
        <Route path="/category" element={<Category/>} />
        <Route path="/add_subcategory" element={<AddSubcategory/>} />
        <Route path="/reports" element={<DailyReports />} />
        <Route path="/editbill" element={<EditBill />} />
        <Route path="/editcontact" element={<Editcontact />} />
      </Routes>
      
    </Router>
  );
}

export default App;
import React, { useEffect, useState } from 'react';
import { ReactSession }  from 'react-client-session';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import SideBar from './sidebar';
import EsideBar from './esidebar';
import NavBar from './navbar';
import Footer from './footer';
ReactSession.setStoreType("localStorage");



 function Add_category() {
    const userId = ReactSession.get("userId");
    const userType = ReactSession.get("userType");
    const userName = ReactSession.get("userName");
    const navigate = useNavigate();
    const indexPath = '/';
 

    const [name, setName] = useState("");
    const [error, setError] = useState('');
    const [category, setCategory] = useState(null);


    useEffect(()=>{
        const fetchData = async () => {
        try {
            const response = await axios.get('https://projects.sninfoserv.com/fbh-api/category/');
            if(response.data.status === "success"){
                setCategory(response.data);
            }
           
           //navigate(`/product`);
        } catch (error) {
            // Handle error
            console.error('There was a problem with login:', error);
            setError('Invalid email or password');
        }
    };
    fetchData();
}, []);




    const handleName = (event) => {
        setName(event.target.value); // Changed setInput to setLink
    };

	

   



    const handleSubmit = async (event) => {
        event.preventDefault();

       
     console.log(name);
     
      
        try {
            const response = await axios.post('https://projects.sninfoserv.com/fbh-api/category/', {  name: name});
            console.log(response.data);
           if(response.data.status === "success"){
            console.log(response.data);
            navigate(`/category`);
        }
        } catch (error) {
            // Handle error
            console.error('There was a problem with login:', error);
            setError('Invalid email or password');
        }
       
    };

    
  return (
   
    <div class="g-sidenav-show   bg-gray-100">
    <div class="min-height-300 bg-primary position-absolute w-100"></div>
    { userType === '1' ?(
    <SideBar />
      ) : (< EsideBar />
     )}

    <main class="main-content position-relative border-radius-lg ">
    
    <NavBar />
    <div class="card shadow-lg mx-2 mt-5 card-profile-bottom">
      <div class="card-body p-2">
        <div class="row gx-4">
          <div class="col-12">
               <div class="nav-wrapper position-relative end-0">
             
			  <div class="tab-content">
					
					 
        <div class="tab-pane fade show active" id="takeAway" role="tabpanel">
					  <div class="card-body">
              <p class="text-uppercase text-sm">Add Category</p>
             <form onSubmit={handleSubmit}>
              <div class="row">
              <div class="col-md-3">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Main Category Name</label>
                    <input type="text"  class="form-control"    placeholder="Enter Product Name..."  value={name}
                                                            onChange={handleName} />
                  </div>
                </div>
               
                <div class="col-md-2 my-4 py-2">
                   <button class="btn btn-primary btn-sm ms-auto">ADD Category</button>
                </div>
               
              </div>
              </form>
             
             
              {/* <div  class="col-md-9">
                   <p style={{ color: 'red' }}>{formError.name}</p>
              </div> */}
              

                
            </div>
					   </div>

         
					   
					   
          </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
    <div class="container-fluid py-4">

      <Footer />
    </div>
  </main>

  </div>
  )
}
export default Add_category;
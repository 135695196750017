import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ReactSession }  from 'react-client-session';
import { useNavigate } from 'react-router-dom';
import SideBar from './sidebar';
import EsideBar from './esidebar';
import NavBar from './navbar';
import Footer from './footer';

function App() {

  const userId = ReactSession.get("userId");
  const userType = ReactSession.get("userType");
  const navigate = useNavigate();
  const indexPath = '/';
  
  useEffect(() => {
    if (userId == 0 ) {
      navigate(indexPath);
    }
    
  }, [userId, navigate]);
  
  let contactID;

if (userType == 1) {
  const queryParameters = new URLSearchParams(window.location.search);
  contactID = queryParameters.get("id");
} else {
  contactID = userId;
}

const value = { "contactID": contactID };

  const [contactdata, Setcontactdata] = useState(true);
  
  const fetchData = async () => {
    try {
      const response = await axios.put('https://projects.sninfoserv.com/fbh-api/contacts/', value);
      Setcontactdata(response.data);
      console.log(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } 
  };
  
  useEffect(() => {
    fetchData();
  }, []);
    
  const getFileType = (fileUrl) => {
    const extension = fileUrl.split('.').pop().toLowerCase();
    if (extension === 'pdf') {
      return 'pdf';
    } else if (['jpg', 'jpeg', 'png', 'gif'].includes(extension)) {
      return 'image';
    } else {
      return 'unknown';
    }
  };
  const empData = contactdata?.data || [];
  const fileUrl1 = empData && empData.length > 0 ? `https://projects.sninfoserv.com/007-api/employees/update/uploads/${empData[0].employee_address_proof}` : `../documents/user.webp`;
  const fileUrl2 = empData && empData.length > 0 ? `https://projects.sninfoserv.com/007-api/employees/update/uploads/${empData[0].employee_id_proof}` : `../documents/user.webp`;
  const fileType1 = getFileType(fileUrl1);
  const fileType2 = getFileType(fileUrl2);


  const [name, setName] = useState('Loading');
  const [phone, setPhone] = useState('Loading');
  const [address, setAddress] = useState('Loading');
  useEffect(() => {
    const iName = contactdata?.data?.[0]?.customer_name;
    const iPhone= contactdata?.data?.[0]?.customer_phone;
    const iAddress = contactdata?.data?.[0]?.customer_address;

    setName(iName);
    setPhone(iPhone);
    setAddress(iAddress);

  }, [contactdata]);
  
  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  

  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };

  

  
  
  const handleAddressChange = (event) => {
    setAddress(event.target.value);
  };

 

  
  const handleFormSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('name', name);
    formData.append('phone', phone);
    formData.append('address', address);
    formData.append('id', contactID);

    try {
      const response = await axios.post('https://projects.sninfoserv.com/fbh-api/contacts/', formData);
       console.log(response.data);
      navigate(`/contacts`);
      fetchData();
    } catch (error) {
      console.error('Error uploading data:', error);
    }

    
  };

   const  removeEmployee = async (employee) => {
    try {
      const response =  await axios.post('https://projects.sninfoserv.com/fbh-api/employees/delete/', { employeeId: employee });
      setAddData(response.data);
      fetchData();
    } catch (error) {
      console.error('Error posting hut_id:', error);
      
    }
  };

  return (
    <div class="g-sidenav-show   bg-gray-100">
    <div class="min-height-300 bg-primary position-absolute w-100"></div>
    { userType === '1' ?(
    <SideBar />
      ) : (< EsideBar />
     )}
    <main class="main-content position-relative border-radius-lg ">
    
    <NavBar />
   
    <div class="container-fluid py-4">
    <div class="row">
        <div class="col-md-8  order-lg-1 order-2">
          <div class="card">
            <div class="card-body">
              <p class="text-uppercase text-sm">Employee Details</p>
              <form onSubmit={handleFormSubmit}>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Name</label>
                        <input class="form-control" type="text" value={name} onChange={handleNameChange} /> 
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Phone Number</label>
                        <input class="form-control" type="text" value={phone} onChange={handlePhoneChange} /> 
                  </div>
                </div>
                
                
                <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Address</label>
                        <textarea class="form-control" type="text" value={address} onChange={handleAddressChange} /> 
                  </div>
                </div>
                <div class="text-center">
                   <button className="btn btn-primary btn-sm mt-3 px-6" style={{marginLeft:'50px'}}>Update</button>
                   </div>
              </div>
              </div>
              </form>
            </div>
          </div>
        </div>
       
      </div>
      
		
        
	
	  

	  
	
      <Footer />
    </div>
  </main>

  </div>
  )
}
export default App;